import { createContext, useContext } from 'react';

export enum FFName {
  BookedDistinction = 'BOOKED_DISTINCTION',
  DonationRequests = 'DONATION_REQUESTS',
  AdsAdmob = 'ADS_ADMOB',
  AddLocation = 'ADD_LOCATION',
  Notation = 'NOTATION',
}
export interface FFContextType {
  ff: (name: FFName) => boolean;
}

export const FFContext = createContext<FFContextType | null>(null);

export const useFFContext = () => useContext(FFContext) as FFContextType;
