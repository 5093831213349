import React from 'react';

import { AbstractRoute, YieldLoveExperiment } from '@/core/lib/router/route';
import AdSense from '@/next/lib/ads/AdSense';
import YieldLove from '@/next/lib/ads/YieldLove';
import YieldLoveNoLazyLoad from '@/next/lib/ads/YieldLoveNoLazyLoad';

const AdsProvider: React.FC<React.PropsWithChildren> = ({ children }) => {
  const {
    common: { adsense, yieldlove },
  } = AbstractRoute.useCommonParams();

  if (adsense === 1) {
    return <AdSense>{children}</AdSense>;
  }

  if (yieldlove === YieldLoveExperiment.REMOVE_LAZY_LOAD) {
    return <YieldLoveNoLazyLoad>{children}</YieldLoveNoLazyLoad>;
  }

  return <YieldLove>{children}</YieldLove>;
};

export default AdsProvider;
